<template>
    <div class="Container">
        <div class="Box">
            <div class="Binder Big">
                <div class="Content">
                    <div class="Box Frame">
                        <h3 class="UserDataTableIndex"><span>Dati utente</span></h3>
                        <div class="UserDataTable">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" summary="In questa tabella vengono elencati i dati di registrazione dell'utente">
                                <tbody>
                                    <tr>
                                        <td>{{ this.risorse("Nome") }}</td>
                                        <td>{{Utente.Nome}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ this.risorse("Cognome") }}</td>
                                        <td>{{Utente.Cognome}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ this.risorse("E-mail") }}</td>
                                        <td>{{Utente.Email}}</td>
                                    </tr>
                                    <!-- <tr >
                                        <td>Azienda</td>
                                        <td>Rainbow srl.</td>
                                    </tr> -->
                                    <tr v-if="this.$store.getters.getShop == 'b2c'">
                                        <td>Telefono:</td>
                                        <td>{{Utente.Telefono}}</td>
                                        <td class="PMTModify">
                                            <div class="Button Stroked Small"><a @click="this.openPopUp()">Modifica</a></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <vue-final-modal v-model="this.showModalTel" classes="modal-container" content-class="modal-content" name="EdiTelephone">
        <div class="Box">
            <form>
                <fieldset>
                    <legend>Modifica Telefono</legend>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Telefono</label>
                            <input type="text" id="Newtelefono" v-model="this.Newtelefono" >
                        </div>
                    </div>
                    <div class="WhiteSpace">&nbsp;</div>
                    <div class="FormRow">
                        <div class="FormBoxWide TwinButtons">
                            <div class="Button Small">
                                <input type="button" @click="showModalTel = false" value="Chiudi">
                            </div>
                            <div class="Button Small">
                                <input type="button" @click="this.SubmitMods()" value="Modifica">
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </vue-final-modal>
</template>

<script>

import {risorseMixin,apiMixin} from "@/mixin.js";
import WebApiService from "@/services/webapi.service.js";

export default {
    name: "DatiUtente",
    mixins:[risorseMixin,apiMixin],

    data(){
        return {
            Utente: this.$store.getters.getAnagraficaUtente,
            showModalTel: false,
            Newtelefono:this.$store.getters.getAnagraficaUtente.Telefono
        }
    },
    methods:{
        closeModal(){           
            this.$vfm.hide('EdiTelephone'); 
        },
        openPopUp(){
            this.$vfm.show('EdiTelephone');
        },
        SubmitMods(){
            if(this.Newtelefono != null ){
                this.Newtelefono = this.Newtelefono.replace(/\D/g, "");
                if(this.Newtelefono.length > 0){
                    this.$store.commit('setLoader', true);
                    WebApiService.post('b2c/Account/SetTelUtente',this.$store.getters.getToken,this.Newtelefono,null).then( data =>{
                        var user = JSON.parse(localStorage.getItem('Utente'));
                        user.Telefono = data.Data.Telefono;
                        localStorage.setItem('Utente', JSON.stringify(user));
                        this.Utente = this.$store.getters.getAnagraficaUtente;
                        this.$vfm.hide('EdiTelephone'); 
                        this.$store.commit('setLoader', false);
                    });
                    this.$store.commit('setLoader', false);
                }  else{
                    this.$toast.error("Inserire un numero di telefono valido!");
                    this.$store.commit('setLoader', false);
                }
            }else{
                this.$toast.error("Inserire un numero di telefono valido!");
                this.$store.commit('setLoader', false);
            }
        }
    }
}
</script>

<style>
.modal-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.modal-content {
    display: flex !important;
    flex-direction: column !important;
    margin: 0 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 0.25rem !important;
    background: #fff !important;
    width: 800px !important;
}

</style>